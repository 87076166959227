@import 'index.scss';

.wrapper {
  position: relative;

  .spinner {
    position: absolute;
    top: 6px;
    left: -24px;
  }

  .downloadButton {
    border: none;
    background: none;
    cursor: pointer;
    padding: 6px 8px;
    margin-right: 8px;

    &.buttonDisabled {
      cursor: not-allowed;

      i {
        color: $bdo-disabled-text;
      }
    }

    &:hover {
      background-color: $bdo-white;
    }
  }

  button {
    &:disabled {
      border: none;
      background: none;
      margin: 0;
    }
  }
}
