@import 'index.scss';

.appHeader {
  width: 100%;
}

.appNav {
  width: $bdo_navigation_width;
}

.appContent {
  width: calc(100% - $bdo_navigation_width);
}
