@import 'index.scss';

.columnWrapper {
  padding: 8px;
  margin-bottom: 24px;
}

.columnWrapperCanDrop {
  border: dashed 1px $bdo-disabled-text;
  border-radius: 4px;
  transition: $bdo-transition;
}
.columnWrapperOver {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  transition: $bdo-transition;

  .headerWrapper {
    background-color: #d4d4d4;
    transition: $bdo-transition;
  }
}

.headerWrapper {
  background-color: $bdo-mid-gray;
  padding: 16px;
  justify-content: space-between;
  margin: 0 0 24px 0;
  border-radius: 4px;

  h5 {
    margin: 0;
    white-space: pre-wrap;
  }

  .addQuestionButton {
    margin-right: 72px;
  }
}
