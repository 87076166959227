.addButton {
  i {
    margin-right: 8px;
  }

  &:disabled {
    i {
      opacity: 0.6;
    }
  }
}
