@import 'index.scss';

.wrapper {
  background-color: #fff;
  border-right: $bdo-border;
  position: fixed;
  z-index: 888;
  width: $bdo-submenu-width;

  .groupsWrapper {
    height: calc(100vh - 280px);
    overflow-y: auto;
    overflow-x: hidden;
    border-top: $bdo-mid-gray 1px solid;
  }

  .groupsWrapperReadOnly {
    height: calc(100vh - 230px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
