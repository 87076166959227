@import 'index.scss';

.closedQuestionWrapper {
  border-left: $bdo-red solid 3px;
  padding: 24px 24px 24px 24px;

  .wrapper {
    justify-content: space-between;
    flex-flow: row nowrap;

    .wrapTitle {
      .dragIcon {
        margin-right: 8px;
        color: $bdo-secondary-text;
        cursor: move;
      }

      .titleWrapper {
        margin-bottom: 0;
        cursor: pointer;
        padding-right: 24px;
        border: none;
        background: none;
        text-align: left;

        h3,
        h4 {
          margin: 0;
          white-space: pre-wrap;
          overflow-wrap: anywhere;
        }

        .infoFont {
          color: $bdo-secondary-text;
          font-family: $bdo-font-family-regular;
          font-size: $bdo-body-font;
          margin-top: 2px;
        }

        &:hover h4 {
          color: $bdo-red;
          transition: $bdo-transition;
        }

        .linkedInfo {
          margin-left: 16px;

          i {
            margin-right: 4px;
          }
        }
      }
    }

    .wrapItems {
      min-width: 470px;
      justify-content: space-between;
      flex-flow: row nowrap;
      @include rwd(1600) {
        flex-flow: row wrap;
        min-width: 260px;
        max-width: 260px;
        justify-content: flex-end;
      }

      .optionsSelector {
        order: 1;
        @include rwd(1600) {
          order: 2;
          margin-top: 16px;
        }
      }

      .iconsWrapper {
        order: 2;
        @include rwd(1600) {
          order: 1;
        }
        margin-left: 16px;
        justify-content: flex-end;

        .errorIcon {
          color: $bdo-red;
          margin: 0 0 0 16px;
        }
      }
    }
  }
}
