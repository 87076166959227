@import 'index.scss';

.wrapper {
  padding: 0 24px 24px 24px;

  h3 {
    margin: 16px 0;
  }

  .clientDetailsWrapper {
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    .column {
      flex-direction: column;
      margin-right: 72px;
      min-width: 320px;

      .infoWrapper {
        margin-bottom: 6px;
        display: flex;
        align-items: center;

        .info {
          flex: 1.5;
          font-family: $bdo-font-family-semibold;
        }

        .label {
          flex: 1;
          color: $bdo-secondary-text;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
