@import 'index.scss';

.formRowWrapper {
  justify-content: space-between;
  align-items: flex-start;

  .filter {
    flex: 1;
    margin-right: 16px;
  }
  .operator {
    flex: 0.5;
    margin-right: 16px;
  }
  .value {
    flex: 2;
    margin-right: 8px;
  }

  .removeFilterButton {
    background: transparent;
    border: none;
    margin-top: 3px;

    &:hover {
      background-color: $bdo-white;
    }

    &:disabled {
      i {
        color: $bdo-disabled-text;
      }

      &:hover {
        background-color: transparent;
        cursor: not-allowed;
      }
    }
  }
}
