@import 'index.scss';

.questionPreview {
  justify-content: space-between;
  background-color: $bdo-highlight-gray;
  margin: 0 0 8px 40px;
  padding: 12px;
  border-left: 3px $bdo-slate solid;
  border-radius: 4px;

  h5 {
    flex: 4;
    overflow-wrap: anywhere;
    line-height: 21px;

    i {
      margin-right: 8px;
      cursor: move;
    }
  }

  &.previewSecondLevel {
    border-left: 3px $bdo-success solid;

    h5 {
      margin: 0 !important;
    }
  }

  &.level2 {
    margin-left: 64px;
  }

  &.level3 {
    margin-left: 88px !important;
  }

  &.level4 {
    margin-left: 112px !important;
  }

  &.sharedSecondLevel {
    border-left: 3px $bdo-success solid;
  }

  &.template {
    border-left: 3px $bdo-mid-gray solid;
    margin-left: 64px;

    h5 {
      margin: 0 !important;
    }
  }

  .infoWrapper {
    width: 330px;
    justify-content: space-between;
  }

  span {
    flex: 1;
    margin-left: 24px;
  }
}
