@import 'index.scss';

.languageSwitcher {
  top: 30px;
  right: 400px;
  position: fixed;
  z-index: 1000;
}

.languageSwitcher button {
  text-decoration: none;
  color: $bdo-charcoal;
  border: none;
  background: none;
  cursor: pointer;
}

.languageSwitcher button:hover {
  text-decoration: underline;
  color: $bdo-red;
}

.languageSwitcher button.active {
  color: $bdo-red;
}

.languageSwitcher button.inactive {
  color: $bdo-charcoal;
}
