.noAuthPage {
  text-align: center;
}

.noAuthPageContent {
  background: url(../../assets/images/app_background.jpg);
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loginWrapper {
  width: 460px;
  padding: 48px 24px;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.2);
}

/*BDO logo*/
.loginWrapper img {
  width: 120px;
  height: auto;
}

.loginWrapper h3 {
  margin: 48px 0 32px 0;
}

.loginWrapper button {
  width: 50%;
}
