@import 'index.scss';

.lastAnsweredQuestionnaireInfo {
  border: $bdo-border;
  border-radius: $bdo-card-border-radius;
  padding: 10px;
  position: relative;
  width: 57%;
  margin-top: 24px;

  &.grayBackground {
    border: $bdo-border;
  }
  &.greenBackground {
    border: 1px solid $bdo-green;
  }

  .boxLabel {
    position: absolute;
    top: -8px;
    left: 6px;
    font-size: $bdo-small-font;
    color: $bdo-disabled-text;
    background-color: $bdo-white;
    padding: 0 4px;
  }

  .wrapper {
    flex: 3;

    .nameWrapper {
      margin-bottom: 4px;

      .info {
        font-size: $bdo-small-font;
        color: $bdo-disabled-text;
        margin-right: 4px;
      }

      .questionnaireName {
        font-size: $bdo-small-font;
      }
    }
  }

  .status {
    flex: 0.84;
  }

  .hiddenButton {
    flex: 1;
  }
}
