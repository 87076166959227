@import 'index.scss';

.message {
  line-height: 20px;
  width: 100%;
  text-align: center;
  padding: 8px 32px;

  &.green {
    background-color: $bdo-light-green;
  }

  &.red {
    background-color: $bdo-light-red;
  }
}
