@import '../../../../index.scss';

button {
  position: relative;
}

.buttonIcon {
  color: $bdo-secondary-text;
  position: absolute;
  top: 10px;
  left: 13px;
}

.userData {
  margin-right: 32px;
}

.userIcon {
  color: $bdo-red;
  background-color: $bdo-highlight-gray;
  padding: 11px;
  border-radius: 50%;
  margin-right: 8px;
}
