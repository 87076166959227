@import 'index.scss';

.barWrapper {
  justify-content: space-between;
  margin: 0 8px 24px 8px;
  width: 100%;

  h3 {
    white-space: pre-wrap;
    overflow-wrap: anywhere;

    .warningIcon {
      margin-right: 8px;
    }

    button i {
      background-color: $bdo-highlight-gray;

      &:hover {
        color: $bdo-charcoal;
        background-color: $bdo-background-gray;
      }
    }
  }

  .addButton {
    i {
      margin-right: 8px;
    }
  }
}

.groupTitle {
  margin: 0;
}

.columns {
  min-width: 280px;
  text-align: right;

  button:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  button:last-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.switchButton {
  height: 44px;
  border: none;
  border-radius: 25px;
  padding: 0 24px;
  cursor: pointer;
  font-family: $bdo-font-family-regular;
  font-size: 14px;
  transition: all 0.4s;
  background-color: $bdo-highlight-gray;

  &:hover {
    background-color: $bdo-mid-gray;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.activeButton {
  background-color: $bdo-charcoal;
  color: #fff;

  &:hover {
    background-color: $bdo-black;
  }
}
