@import '../../../index.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: $bdo-background-gray;
  font-family: $bdo-font-family-regular;
  font-size: $bdo-body-font;
  color: $bdo-primary-text;
}

a {
  transition: all 0.4s ease-in-out;
}

.pagePadding {
  padding: 120px 32px 24px 32px;
}

p {
  margin: 0;
}

h2,
h3,
h4,
h5 {
  font-family: $bdo-font-family-semibold;
  color: $bdo-primary-text;
}

h2 {
  font-size: 30px;
  line-height: 36px;
}

h3 {
  font-size: 22px;
  line-height: 28px;
}

h4 {
  font-size: 18px;
  line-height: 22px;
}

h5 {
  font-size: 16px;
}

h2.pageTitle,
h3.modalTitle,
h4.subtitle,
h5.subtitle {
  margin: 0 0 16px 0;
}

.emptyStateText {
  margin: 0 0 24px 0;
  font-size: 14px;
  font-family: $bdo-font-family-regular;
  color: $bdo-disabled-text;
  font-style: normal;
  font-weight: 600;
}

table {
  font-family: 'proximanova-regular', sans-serif;
}

i {
  font-size: 20px;
  color: $bdo-secondary-text;
}

.iconButton {
  transition: $bdo-transition;
  padding: 6px 12px;
  border-radius: 24px;
  cursor: pointer;

  &:hover {
    background-color: $bdo-highlight-gray;
  }

  &:hover i {
    color: $bdo-charcoal;
  }
}

.card {
  background-color: $bdo-white;
  border: $bdo-border;
  border-radius: 4px;
  padding: 24px;
}

.cardTable {
  padding: 24px 0;
}

.textButton,
a.textButton {
  color: $bdo-red;
  font-family: $bdo-font-family-regular;
  padding: 4px 0;
}

.textButton {
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

a.textButton {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.redText {
  color: $bdo-red;
}

.blackText {
  color: $bdo-charcoal;
}

.open,
.visible {
  display: block;
}

.closed,
.hidden {
  display: none;
}

.transparent {
  opacity: 0;
  transition: none;
}

.visibilityHidden {
  visibility: hidden;
  transition: none;
}

//fixes autofill blue coloring
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

//styles for condition flag in questionnaire dropdown in account/condition
.flagInDropdown .ant-select-item-option-content {
  display: flex !important;
  justify-content: space-between !important;
  margin-right: 8px;
}

.flagInDropdown.ant-select-item-option-selected .ant-select-item-option-content .flag {
  color: $bdo-white !important;
}

.flagInDropdown .ant-select-item-option-content .flag {
  font-size: $bdo-small-font;
  color: $bdo-disabled-text;
}

// for default breaking words without spaces
.noSpacesText {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}

.hintIcon {
  margin-left: 6px;
  margin-top: 6px;
  opacity: 0.6;
  transition: $bdo-transition;
  font-size: 16px;

  &:hover {
    opacity: 1;
  }
}

#ClientTable {
  tr:nth-child(even) {
    background-color: $bdo-table-row !important;
  }
}
