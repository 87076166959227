@import 'index.scss';

.tabWrapper {
  position: relative;
  transition: $bdo-transition;

  &:hover,
  &:focus-visible,
  &:focus-within {
    .moreIcon {
      display: block !important;
      background-color: rgba(230, 230, 230, 0.2);
      border-radius: 24px;
    }

    .errorIcon {
      display: none;
    }
  }
}

.tab {
  font-family: $bdo-font-family-semibold;
  font-size: 14px;
  text-align: left;
  overflow-wrap: anywhere;
  padding: 21px 44px 21px 8px;
  cursor: pointer;
  transition: all 0.3s;
  justify-content: space-between;
  white-space: pre-wrap;
  width: $bdo-submenu-width;
  border: none;
  border-bottom: $bdo-mid-gray 1px solid;
  background-color: #fff;
  position: relative;

  &:hover {
    background-color: $bdo-highlight-gray;
  }

  .dragIcon {
    cursor: move;
    margin-right: 4px;
    color: $bdo-secondary-text;
  }

  .errorIcon {
    position: absolute;
    right: 16px;
    top: 22px;
  }
}

.tabActive {
  background-color: $bdo-red;
  color: #fff;
  transition: all 0.3s;

  &:hover {
    background-color: $bdo-urgent-red;
  }

  .dragIcon {
    color: #fff;
  }

  .errorIcon {
    display: none;
  }
}

.iconsSet {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;

  .contextMenuButton {
    background-color: transparent;
    color: $bdo-secondary-text;
    border: none;
    cursor: pointer;

    .moreIcon {
      padding: 2px 6px;
    }
  }
}

.iconWhite {
  color: $bdo-white;
}

.iconColor {
  color: $bdo-secondary-text;

  &:hover {
    color: $bdo-charcoal;
  }
}

.deleteGroupButton {
  background-color: transparent;
  font-family: $bdo-font-family-regular;
  border: none;
  cursor: pointer;
  font-size: $bdo-body-font;
  padding: 8px 0;
  display: flex;
  align-items: center;
  transition: $bdo-transition;

  i {
    margin-right: 2px;
    padding: 8px;
  }

  &:hover {
    color: $bdo-red;
  }
}
