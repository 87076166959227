@import 'index.scss';

.wrapper {
  position: relative;

  .spinner {
    position: absolute;
    z-index: 890;
    top: 110px;
    left: 50%;
    transform: translateX(-50%);
  }

  .tabsWrapper {
    position: sticky;
    padding-left: 24px;
    background: $bdo-white;
    border-bottom: $bdo-border;
    z-index: 800;

    &.noNotification {
      top: 193px;
    }

    &.isNotification {
      top: 228px;
    }
  }

  .clientInfo {
    padding-top: 90px;
  }
}
