@import 'index.scss';

.wrapper {
  position: relative;
}

.contentWrapper {
  margin-top: 80px;
}

.questionGroupsWrapper {
  width: $bdo-submenu-width;
}

.questionsWrapper {
  width: calc(100% - $bdo-submenu-width);
  padding: 24px;
}

.spinner {
  position: absolute;
  z-index: 890;
  top: 110px;
  left: 50%;
  transform: translateX(-50%);
}
