@import 'index.scss';

.tab {
  font-family: $bdo-font-family-semibold;
  font-size: 14px;
  padding: 24px 16px;
  cursor: pointer;
  transition: all 0.3s;
  justify-content: space-between;
  white-space: pre-wrap;
  width: $bdo-submenu-width;
  border: none;
  border-bottom: $bdo-mid-gray 1px solid;
  border-right: $bdo-mid-gray 1px solid;
  background-color: $bdo-white;
  position: relative;

  &:hover {
    background-color: $bdo-highlight-gray;
  }

  &[disabled] {
    cursor: not-allowed;

    &:hover {
      background-color: $bdo-white;
    }
  }
}

.tabActive {
  background-color: $bdo-red;
  color: #fff;
  transition: all 0.3s;

  &:hover {
    background-color: $bdo-urgent-red;
  }
}
