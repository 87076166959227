@import 'index.scss';

.filterWrapper {
  background-color: $bdo-highlight-gray;
  padding: 16px 24px;

  h3 {
    margin: 0 0 16px 0;
  }

  .labels {
    justify-content: space-between;
    padding: 4px 0;

    span {
      &:first-child {
        flex: 0.98;
      }
      &:nth-child(2) {
        flex: 0.52;
      }
      &:nth-child(3) {
        flex: 2;
      }
    }
  }

  .addFilterButton {
    background-color: transparent;
    border: none;
    font-family: $bdo-font-family-regular;
    font-size: $bdo-body-font;
    padding: 6px 12px;
    border-radius: 16px;
    transition: $bdo-transition;
    cursor: pointer;
    justify-items: center;
    align-items: center;

    &:hover {
      background-color: $bdo-white;
    }

    i {
      margin-right: 4px;
    }

    &:disabled {
      cursor: not-allowed;

      i {
        color: $bdo-disabled-text;
      }
      &:hover {
        background-color: transparent;
      }
    }
  }

  .clearCloseButton {
    background-color: $bdo-white;
    margin-left: 16px;
  }
}
