.twoColumnsWrapper {
  margin-top: 16px;
  position: relative;

  .columnCard {
    padding-bottom: 100px;
    padding-right: 32px;
    flex: 1;

    &:first-child {
      margin-right: 12px;
    }
    &:last-child {
      margin-left: 12px;
    }
  }
}
