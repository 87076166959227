@import '../../index.scss';

.errorInfoWrapper {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.errorInfoWrapper h3 {
  margin: 8px 0 4px;
}

.errorInfoWrapper button {
  margin-top: 24px;
}
