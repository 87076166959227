/*Variables - app styles*/

/*Fonts*/
$bdo-body-font: 14px;
$bdo-small-font: 12px;
$bdo-font-family-regular: 'proximanova-regular', sans-serif;
$bdo-font-family-semibold: 'proximanova-semibold', sans-serif;

/*Colors*/
$bdo-background-gray: #f1f1f3;
$bdo-highlight-gray: #f7f6f7;
$bdo-mid-gray: #e5e5ea;
$bdo-charcoal: #333;
$bdo-black: #000;
$bdo-red: #ed1a3b;
$bdo-urgent-red: #ad1931;
$bdo-warning: #fd7e14;
$bdo-success: #54a575;
$bdo-primary-text: $bdo-charcoal;
$bdo-secondary-text: #6f6f6f;
$bdo-disabled-text: #959597;
$bdo-white: #fff;
$bdo-slate: #657c91;
$bdo-light-green: #e6f6ed;
$bdo-green: #b4e9cb;
$bdo-light-red: #fff1f0;
$bdo-submenu: #242323;
$bdo-table-row: #eaeaea;

/*Borders*/
$bdo-border: 1px solid $bdo-mid-gray;
$bdo-card-border-radius: 4px;

/*Block widths*/
$bdo_navigation_width: 280px;
$bdo-submenu-width: 280px;

/*Transitions*/
$bdo-transition: all 0.4s;

/*Breakpoints and media queries*/
$screen-xl-max: 1400px;
$screen-lg-max: 1200px;
$screen-md-max: 992px;
$screen-sm-max: 768px;
$screen-xs-max: 576px;

@mixin xl {
  @media (max-width: #{$screen-xl-max}) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin xs {
  @media (max-width: #{$screen-xs-max}) {
    @content;
  }
}
// Custom devices
@mixin rwd($screen) {
  @media (max-width: #{$screen+'px'}) {
    @content;
  }
}

@font-face {
  font-family: 'proximanova-regular';
  src: url('assets/fonts/proximanova-regular.woff2') format('woff2'), url('assets/fonts/proximanova-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proximanova-semibold';
  src: url('assets/fonts/proximanova-semibold.woff2') format('woff2'), url('assets/fonts/proximanova-semibold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

td.ant-table-column-sort {
  background-color: transparent !important;
}

td.ant-table-cell-row-hover {
  background-color: rgb(255, 240, 240) !important;
}

.ant-table .ant-table-thead > tr > th.ant-table-column-sort {
  color: #ed1a3b;
}

.ant-table .ant-table-thead > tr > th {
  color: #9e9e9e;
  font-weight: 600;
  background-color: transparent;
}

.ant-btn-loading-icon {
  padding-right: 8px;
}

.ant-btn-sm {
  padding: 4px 14px !important;
  border-radius: 32px !important;
  height: 32px !important;
}

.ant-checkbox-group {
  flex-direction: column;
}

.ant-select-selection-item-remove {
  color: rgba(0, 0, 0, 0.54) !important;
}
