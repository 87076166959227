@import 'index.scss';

// linked questions styling
.questionWrapper .questionRef {
  border-left: 3px solid $bdo-slate;
  border-radius: $bdo-card-border-radius;
}

.questionWrapperNestedOne .questionRef {
  border-left: 3px solid $bdo-success;
}

// template questions styling
.questionWrapper .questionTemplateRef .questionRef,
.questionTemplateRef .questionRef {
  background-color: #fcfbfc;
  margin-left: 0;
  border-left: $bdo-border;

  .buttonWhite {
    background-color: $bdo-white;
    opacity: 1;
    border: $bdo-mid-gray 1px solid;

    &:hover {
      background-color: $bdo-mid-gray;
    }
  }
}

.questionWrapper .childTemplateRef,
.childTemplateRef {
  background-color: $bdo-white;
  margin-left: 0;
  border-left: $bdo-border !important;

  .buttonWhite {
    background-color: $bdo-highlight-gray !important;
    opacity: 1;
    border: none !important;

    &:hover {
      background-color: $bdo-mid-gray !important;
    }
  }
}

//menu active classes
.navLink {
  border-left: 3px solid $bdo-charcoal;
}

.navLink.active {
  border-left: 3px solid $bdo-red;
  background: $bdo-black;
}

.ledgerLink {
  border-left: 3px solid $bdo-submenu;
}

.ledgerLink.active {
  border-left: 3px solid $bdo-red;
  background: $bdo-black;
}
