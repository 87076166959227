@import 'index.scss';

.infoWrapper {
  justify-content: flex-start;

  .info {
    font-size: $bdo-small-font;
    color: $bdo-disabled-text;
  }

  .editInfo {
    font-size: $bdo-small-font;
  }

  .editInfo span {
    padding-left: 4px;
  }
}
