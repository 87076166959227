@import 'index.scss';

.tab {
  padding: 16px 24px;
  border: none;
  background-color: $bdo-white;
  border-bottom: 2px $bdo-white solid;
  font-family: $bdo-font-family-regular;
  font-size: $bdo-body-font;
  color: $bdo-secondary-text;
  cursor: pointer;
  transition: $bdo-transition;

  &:hover {
    color: $bdo-red;
  }

  &.tabActive {
    border-bottom: 2px $bdo-red solid;
    color: $bdo-red;
    font-family: $bdo-font-family-semibold;
  }
}
