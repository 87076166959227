@import 'index.scss';

.importButtonWrapper {
  margin-left: 16px;
}

.browserButtonWrapper {
  text-align: center;

  img {
    width: 60px;
    height: auto;
  }

  h5 {
    margin: 0;
  }

  .browseButton {
    text-align: center;
    margin-top: 24px;
  }

  .errorMessage {
    background-color: $bdo-light-red;
    text-align: center;
    padding: 8px 16px;
    margin-top: 16px;
    border-radius: 4px;
  }
}
