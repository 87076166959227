@import 'index.scss';

.wrapper {
  padding: 0 24px 24px 24px;

  h3 {
    margin: 16px 0;
  }
  .controls {
    margin: 0 24px 0 0;
    text-align: right;
    justify-content: space-between;

    .toggleButton {
      margin-top: -16px;
    }
  }
}
