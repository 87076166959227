.detailsWrapper {
  h3 {
    margin: 0 0 24px 0;
  }
}

.formWrapper {
  max-width: 700px;
}

.saveButtonWrapper {
  margin-top: 24px;

  .saveButton {
    margin-right: 16px;
  }

  .unsavedChanges {
    margin-right: 16px;

    i {
      margin-right: 4px;
    }
  }
}
