@import 'index.scss';

.emptyStateInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
}

.emptyStateText {
  font-weight: 600;
}
