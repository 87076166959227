@import 'index.scss';

.wrapper {
  padding: 100px;
  background-color: white;
}

.contentWrapper {
  width: 100%;
  margin-top: 80px;
}

.accountTabs {
  width: $bdo-submenu-width;
  background-color: $bdo-white;
  height: calc(100vh - 190px);
  border-right: $bdo-border;
}

.accountContent {
  width: calc(100% - $bdo-submenu-width);
  padding: 24px;
}
