@import 'index.scss';

.editIcon {
  font-size: 18px;
  transition: $bdo-transition;
  background-color: transparent;
  &:hover {
    background-color: $bdo-white;
  }
}

.popupTrigger {
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  text-align: left;
  display: inline;

  .editIcon {
    padding: 8px;
  }

  &:hover {
    color: $bdo-red;
  }
  button {
    &:disabled {
      background: none !important;
      border: none;
      opacity: 0.6;
    }
  }

  .buttonText {
    margin-left: 4px;
    transition: $bdo-transition;
    font-family: $bdo-font-family-regular;
    font-size: $bdo-body-font;
  }
}

.buttonTrigger {
  min-width: 40px;
  min-height: 40px;
  margin-left: 8px;
  border-radius: 100%;
  border: 1px solid #d9d9d9;
  background: #f7f6f7;
  cursor: pointer;
  justify-content: center;

  &:hover {
    color: $bdo-red;
  }
  button {
    &:disabled {
      background: none !important;
      border: none;
      opacity: 0.6;
    }
  }

  .editIcon {
    padding-left: 3px;
  }

  .buttonText {
    margin-left: 4px;
    transition: $bdo-transition;
    font-family: $bdo-font-family-regular;
    font-size: $bdo-body-font;
  }
}
