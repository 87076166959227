@import 'index.scss';

.publishedQuestionnaire {
  .publishedQuestionnaireWrapper {
    flex: 6;
    justify-content: flex-start;

    img {
      width: 37px;
      height: auto;
      margin-right: 8px;
    }

    .publishedQuestionnaireTitle {
      flex: 3;
      margin-right: 4px;

      h4 {
        margin: 0 8px 0 0;
        overflow-wrap: anywhere;
      }
    }
  }

  .statusBadge {
    flex: 2;

    .status {
      margin-top: 5px;
    }
  }

  .editButton {
    flex: 2;
    text-align: right;
  }
}
