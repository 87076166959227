@import 'index.scss';

.archiveButton {
  border: none;
  background: none;
  cursor: pointer;
  padding: 6px 8px;
  margin-right: 8px;

  &:hover {
    background-color: $bdo-white;
  }
}
