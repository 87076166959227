@import 'index.scss';

.wrapper {
  position: relative;
}

.contentWrapper {
  width: 100%;
  margin-top: 80px;
}

.questionGroupsWrapper {
  width: $bdo-submenu-width;
}

.questionsWrapper {
  width: calc(100% - $bdo-submenu-width);
  padding: 24px 16px;
}
