@import 'index.scss';

.tabWrapper {
  position: relative;
  transition: $bdo-transition;

  .tab {
    font-family: $bdo-font-family-semibold;
    font-size: 14px;
    text-align: left;
    overflow-wrap: anywhere;
    padding: 21px 44px 21px 8px;
    cursor: pointer;
    transition: all 0.3s;
    justify-content: space-between;
    white-space: pre-wrap;
    width: $bdo-submenu-width;
    border: none;
    border-bottom: $bdo-mid-gray 1px solid;
    background-color: $bdo-white;
    position: relative;

    &:hover {
      background-color: $bdo-highlight-gray;
    }

    .errorIcon {
      position: absolute;
      right: 16px;
      top: 22px;
    }

    .acceptIcon,
    .acceptIconGray {
      position: absolute;
      right: 16px;
      top: 22px;
    }

    .acceptIcon {
      color: $bdo-white;
    }

    .acceptIconGray {
      color: $bdo-secondary-text;
    }
  }

  .tabActive {
    background-color: $bdo-red;
    color: $bdo-white;
    transition: all 0.3s;

    &:hover {
      background-color: $bdo-urgent-red;
    }

    .dragIcon {
      color: $bdo-white;
    }

    .errorIcon {
      display: none;
    }
  }
}
