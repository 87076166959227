@import '../../index.scss';

header {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  height: 80px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.onboardingtoolLogo {
  width: 80px;
  height: 80px;
  background-color: $bdo-red;
  padding: 7px;
}

.onboardingtoolLogo img {
  width: 65px;
  height: auto;
}

.logoWrapper {
  background-color: $bdo-black;
  height: 80px;
  min-width: $bdo-navigation-width;
}

.bdoLogo {
  height: 35px;
  width: auto;
  margin-left: 24px;
}

.contentWrapper {
  width: calc(100vw - $bdo-navigation-width);
  padding: 0 32px;
  justify-content: space-between;
}
