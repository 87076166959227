.navigationWrapper {
  position: absolute;
  bottom: 24px;
  left: 24px;
  justify-content: space-between;
  width: 100%;
  padding-right: 48px;

  i {
    font-size: 14px;
  }

  .nextButton {
    i {
      margin-left: 8px;
    }
  }
}
