@import 'index.scss';

.mainInfoWrapper {
  border: $bdo-border;
  border-radius: $bdo-card-border-radius;
  padding: 10px;
  position: relative;
  width: 43%;
  margin: 24px 2% 0 0;

  .boxLabel {
    position: absolute;
    top: -8px;
    left: 6px;
    font-size: $bdo-small-font;
    color: $bdo-disabled-text;
    background-color: $bdo-white;
    padding: 0 4px;
  }

  .infoWrapper {
    margin-top: 4px;
    justify-content: flex-start;

    .info {
      font-size: $bdo-small-font;
      color: $bdo-disabled-text;
    }

    .editInfo {
      font-size: $bdo-small-font;
    }

    .editInfo span {
      padding-left: 4px;
    }
  }
}
