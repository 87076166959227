@import 'index.scss';

.topBarWrapper {
  background-color: #fff;
  border-bottom: $bdo-border;
  justify-content: space-between;
  position: sticky;
  top: 80px;
  left: $bdo_navigation_width;
  width: 100%;
  z-index: 889;

  .archivedMessage {
    background-color: $bdo-light-green;
    line-height: 36px;
    width: 100%;
    text-align: center;
  }

  .wrapper {
    justify-content: space-between;
    padding: 16px 24px;
    width: 100%;

    .pageTitle {
      .avatar {
        background: $bdo-highlight-gray;
        padding: 12px;
        border-radius: 50%;
        font-size: 26px;
        margin-right: 8px;
      }

      h2 {
        margin: 0;
        white-space: pre-wrap;
        overflow-wrap: anywhere;
      }

      p {
        margin: 0;
      }

      button i {
        background-color: $bdo-highlight-gray;

        &:hover {
          background-color: $bdo-background-gray;
          color: $bdo-charcoal;
        }
      }

      .questionnaireInfo {
        margin-top: 4px;
        .pipe {
          padding: 0 8px;
        }
      }
    }
  }
}
