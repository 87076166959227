@import 'index.scss';

.optionWrapper {
  background: $bdo-highlight-gray;
  border-radius: $bdo-card-border-radius;
  border-left: 3px solid $bdo-red;
  padding: 12px 16px 12px 8px;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;

  &.noneOption {
    background: $bdo-mid-gray;
  }

  &.optionWrapperNestedOne {
    border-left: 3px solid $bdo-slate;
  }

  &.optionWrapperNestedTwo {
    border-left: 3px solid $bdo-success;
  }

  .textField {
    position: relative;
    flex: 1;

    .dragIcon {
      margin-right: 8px;
      color: $bdo-secondary-text;
      cursor: move;
    }

    .removeButton {
      margin: 0 0 0 10px;
    }
  }

  .chevron {
    min-width: 64px;
    padding-left: 24px;

    .chevronButton {
      margin-top: 4px;
      border: none;
      background-color: $bdo-highlight-gray;

      .iconUpsideDown {
        transition: $bdo-transition;
        transform: rotate(-90deg);
      }
    }
  }
}

.radio {
  margin-top: -16px;
}

.questionPreview {
  justify-content: space-between;
  background-color: $bdo-highlight-gray;
  margin: 0 0 16px 40px;
  padding: 16px;
  border-left: 3px $bdo-slate solid;
  border-radius: 4px;

  h5 {
    flex: 5;
    overflow-wrap: anywhere;
    line-height: 21px;

    i {
      margin-right: 8px;
    }
  }

  span {
    flex: 1;
    margin-left: 24px;
  }
}
