@import '../../index.scss';

.searchField {
  width: 50%;
  padding: 0 24px 16px 24px;

  button {
    border-radius: 2px;
  }

  @include md {
    width: 100%;
  }
}
