@import 'index.scss';

.questionWrapper {
  justify-content: space-between;
  position: relative;

  .field {
    flex: 1;

    .radioButton {
      display: block;
    }
  }

  .checkBoxGroup label:first-child {
    border-bottom: $bdo-border;
    padding: 0 100px 8px 0;
  }

  .hint {
    position: absolute;
    top: 30px;
    right: -24px;

    .hintIcon {
      margin-left: 4px;
      opacity: 0.6;
      transition: $bdo-transition;
      font-size: 16px;

      &:hover {
        opacity: 1;
      }
    }
  }

  .booleanWrapper {
    position: relative;

    .booleanQuestion {
      border: $bdo-border;
      border-radius: $bdo-card-border-radius;
      padding: 8px 16px;
    }
    .hintBoolean {
      position: absolute;
      top: 18px;
      right: -24px;

      .hintIcon {
        margin-left: 4px;
        opacity: 0.6;
        transition: $bdo-transition;
        font-size: 16px;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.generatedQuestion {
  margin-left: 32px;
  position: relative;

  .number {
    background-color: $bdo-mid-gray;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    display: inline-block;
    text-align: center;
    position: absolute;
    top: 2px;
    left: -30px;
    font-size: $bdo-small-font;
    padding-top: 3px;
  }
}
