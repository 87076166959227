@import 'index.scss';

.wrapper {
  background-color: $bdo-white;
  border-right: $bdo-border;
  position: fixed;
  z-index: 888;
  width: $bdo-submenu-width;
}

.groupsWrapper {
  height: calc(100vh - 195px);
  overflow-y: auto;
  overflow-x: hidden;
  border-top: $bdo-mid-gray 1px solid;
}
