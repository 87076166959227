@import '../../../index.scss';

.flex {
  display: flex;
  flex-flow: row nowrap;
}

.alignItems {
  align-items: center;
}

.noMargin {
  margin: 0;
}

.mb-1 {
  margin-bottom: 8px;
}

.mr-1 {
  margin-right: 8px;
}

.ml-1 {
  margin-left: 8px;
}

.mb-2 {
  margin-bottom: 16px;
}

.mr-2 {
  margin-right: 16px;
}

.ml-2 {
  margin-left: 16px;
}

.mt-2 {
  margin-top: 16px;
}

.m-3 {
  margin: 24px;
}

.p-3 {
  padding: 24px;
}

.mt-3 {
  margin-top: 24px;
}

.mb-3 {
  margin-bottom: 24px;
}

.ml-3 {
  margin-left: 24px;
}

.mr-3 {
  margin-right: 24px;
}

.pt-3 {
  padding-top: 24px;
}

.pb-3 {
  padding-bottom: 24px;
}

.pl-3 {
  padding-left: 24px;
}

.pr-3 {
  padding-right: 24px;
}
