@import 'index.scss';

.conditionForm {
  .conditionWrapper {
    h3 {
      margin: 0 0 24px 0;
    }

    .radioButton {
      display: block;
    }

    .wrapper {
      align-items: center;
      max-width: 850px;
      flex-flow: row wrap;

      .selectField {
        max-width: 650px;
        display: block;
        margin-right: 16px;
      }

      .conditionFlag {
        display: none;
      }

      .clearButton {
        @include rwd(1482) {
          margin-bottom: 16px;
        }
      }
    }

    h5 {
      margin: 0;
    }

    .saveButtonWrapper {
      margin-top: 24px;

      .saveButton {
        margin-right: 16px;
      }

      .unsavedChanges {
        margin-right: 16px;

        i {
          margin-right: 4px;
        }
      }
    }

    .rangeWrapper {
      margin-top: 16px;
      .rangeField {
        width: 100px;
      }

      .dash {
        margin: 0 8px 24px 8px;
      }
    }
  }
}
