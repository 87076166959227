@import 'index.scss';

.pageWrapper {
  padding: 0 24px 24px 24px;

  h3 {
    margin: 16px 0;
  }

  .serviceWrapper {
    flex-direction: column;

    .service {
      width: 55%;
      min-width: 820px;
      margin-bottom: 24px;
      padding: 0;

      .serviceTitleWrapper {
        border-bottom: $bdo-border;
        padding: 12px 24px;

        h5 {
          margin: 0;
        }
      }

      .appsWrapper {
        .singleAppWrapper {
          padding: 16px 24px 24px 24px;
          border-bottom: $bdo-border;

          &:last-child {
            border: none;
          }

          .publishedQuestionnaire {
            justify-content: space-between;

            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
}
