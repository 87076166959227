@import '../../index.scss';

.navWrapper {
  width: $bdo-navigation-width;
  background-color: #333333;
  height: calc(100vh - 80px);
  padding-top: 80px;
  position: fixed;
  top: 80px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .navItemWrapper {
    display: flex;
    align-items: center;
    padding-left: 24px;

    i {
      color: $bdo-white;
      margin-right: 8px;
    }
  }

  .link {
    color: $bdo-white;
    text-decoration: none;
    font-size: 16px;
    padding: 20px 0;
    display: block;
    width: 100%;

    &:hover {
      border-left: 3px solid $bdo-red;
      background-color: $bdo-black;
    }
  }

  .ledgerMenuWrapper {
    background: $bdo-submenu;

    .ledgerLink {
      padding: 14px 52px;
      color: $bdo-white;
      font-size: 14px;
      text-decoration: none;
      display: block;

      &:hover {
        background-color: $bdo-black;
        border-left: $bdo-red 3px solid;
      }
    }
  }

  .ledgerToggle {
    background-color: $bdo-submenu;
    border: none;
    font-family: $bdo-font-family-regular;
    color: $bdo-white;
    font-size: 16px;
    padding: 20px 16px 20px 28px;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
    transition: $bdo-transition;

    &:hover {
      background-color: $bdo-submenu;
    }

    i {
      color: $bdo-white;
    }

    .dictionary {
      margin-right: 4px;
    }

    .chevron {
      font-size: 12px;
    }

    .openChevron {
      transform: rotate(90deg);
      transition: $bdo-transition;
    }

    .closedChevron {
      transform: rotate(0deg);
      transition: $bdo-transition;
    }
  }

  .new {
    background-color: $bdo-charcoal;
  }

  .versionInfo {
    color: $bdo-white;
    opacity: 0.4;
    padding: 24px;
    font-family: $bdo-font-family-regular;
  }
}
