@import 'index.scss';

.ant-form-item,
.ant-form-item.ant-form-item-has-success {
  margin-bottom: 24px !important;
  width: 100% !important;
}

.ant-form-item-explain {
  padding: 0 !important;
  min-height: 24px !important;
}

.ant-form-item.ant-form-item-has-error {
  margin-bottom: 0 !important;
}

.ant-table-pagination.ant-pagination {
  padding-right: 24px !important;
}

.ant-table-thead {
  background-color: #fff;
  position: sticky;
  top: 80px;
  z-index: 799;
}

.ant-tabs-top .ant-tabs-nav-wrap {
  background-color: #fff !important;
  border: $bdo-border;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ant-tabs-top .ant-tabs-tab-active {
  border-bottom: 2px solid $bdo-red !important;
}

.ant-input-number {
  width: 100% !important;
}

//question options Ant styles
.optionField .ant-form-item,
.setDefaultOption.ant-form-item {
  margin-bottom: 0 !important;
}

.radioOff .ant-radio {
  display: none !important;
}

.radioOn .ant-radio {
  display: block !important;
}

.ant-radio-group.ant-radio-group-outline {
  width: 100% !important;
}

.ant-popover-inner-content {
  padding: 16px 20px 10px 16px !important;
}

//questionnaire List action column styles
.questionnaireListActions {
  padding: 12px 4px 12px 16px !important;
  text-align: right;
}

//condition options list styling
.conditionOption.ant-form-item {
  margin-bottom: 0 !important;
  overflow-wrap: anywhere !important;
}

//linked question 1 and 2 level display field type

.questionRef .ant-radio-group {
  font-size: $bdo-body-font;
}

//form in general ledger filters

.refFilterForm .ant-form-item,
.refFilterForm .ant-form-item.ant-form-item-has-success {
  margin-bottom: 16px !important;
}

//date picker field
.ant-picker {
  width: 100%;
}

// Font weight of form label in questionnaire to be answered
.questionsToAnswer .ant-form-item-label label {
  font-family: $bdo-font-family-semibold;
}

//selector of options in shared questions
.optionSelector .ant-form-item {
  margin-bottom: 0 !important;
}

.checkBoxRef label {
  display: flex;
  margin: 8px 0;
}

// loader component
.loaderRef .ant-modal-content {
  border-radius: 8px;
}

.ant-skeleton-content h3 {
  margin: 1px 0 !important;
}
