@import 'index.scss';

.descriptionForm {
  .descriptionWrapper {
    h3 {
      margin: 0 0 24px 0;
    }

    .wrapper {
      align-items: center;
      max-width: 850px;
      flex-flow: row wrap;

      .selectField {
        max-width: 650px;
        display: block;
        margin-right: 16px;
      }

      .descriptionFlag {
        display: none;
      }

      .clearButton {
        @include rwd(1493) {
          margin-bottom: 16px;
        }
      }
    }
  }

  .saveButtonWrapper {
    margin-top: 24px;

    .saveButton {
      margin-right: 16px;
    }

    .unsavedChanges {
      i {
        margin-right: 4px;
      }
    }
  }
}
