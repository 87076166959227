@import 'index.scss';

.questionWrapper {
  .formWrapper,
  .titleWrapper {
    justify-content: space-between;
  }

  .titleWrapper {
    margin-bottom: 16px;

    h3,
    h4 {
      margin: 0;
      white-space: pre-wrap;
      overflow-wrap: anywhere;
    }

    .dragIcon {
      margin-right: 8px;
      color: $bdo-secondary-text;
      cursor: move;
    }

    button i {
      font-size: 15px;
      top: 10px;
      left: 13px;
    }
  }

  .formWrapper {
    .column {
      flex: 1;

      &:last-child {
        margin-left: 24px;
      }
    }
  }

  .typesContent {
    .optionsWrapper {
      margin-bottom: 24px;

      h5 {
        margin: 0;
      }

      .optionSwitch {
        margin: 0 24px 16px 0;

        .hintIcon {
          margin-top: 6px;
          opacity: 0.6;
          transition: $bdo-transition;
          font-size: 16px;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .multipliedWrapper {
      margin-bottom: 24px;

      .multiplied {
        max-width: 49%;
      }

      .templateWarning {
        background-color: $bdo-light-red;
        justify-content: center;
        padding: 8px 16px;
        max-width: 320px;
        margin-bottom: 16px;
        border-radius: 4px;

        i {
          color: $bdo-red;
          margin-right: 8px;
        }
      }

      h5 {
        margin: 0 0 8px 0;
      }
    }

    .sharedQuestionsWrapper {
      padding-bottom: 24px;
      h5 {
        margin: 0 0 16px;
      }
    }
  }
}
